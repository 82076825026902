import React, { useState, useEffect } from 'react';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../firebase/firebaseConfig';

import BioSection from "../components/bioSection";
import ContactSection from "../components/contactSection";
import GigSection from "../components/gigSection";
import MediaSection from "../components/mediaSection";
import RiderSection from "../components/riderSection";
import StatSection from "../components/statSection";
import PhotoSection from "../components/photoSection";
import HeroSection from "../components/heroSection";
import Layout from "../layouts/layout";
import Footer from "../components/footer";
import BoxLayout from "../layouts/boxlayout";

const EPK = () => {
  // State to manage section visibility
  const [sectionsVisibility, setSectionsVisibility] = useState({
    bio: false,
    gigs: false,
    media: false,
    photos: false,
    rider: false,
    stat: false,
  });
  
  // Fetch the sections visibility from Firestore
  useEffect(() => {
    const fetchSectionsVisibility = async () => {
      try {
        const sectionsDocRef = doc(db, 'sections', 'Qdh1hv7nAxwzFsvQIqk3');
        const sectionsDoc = await getDoc(sectionsDocRef);
        if (sectionsDoc.exists()) {
          setSectionsVisibility(sectionsDoc.data());
        } else {
          console.error("No such document found in sections collection!");
        }
      } catch (error) {
        console.error("Error fetching section visibility:", error);
      }
    };

    fetchSectionsVisibility();
  }, []);

  return (
    <>
      <HeroSection />
      
      {/* Render BoxLayout for Bio and Stat only if one of them is true */}
      {(sectionsVisibility.bio || sectionsVisibility.stat) && (
        <BoxLayout bgColor="bg-user-bg">
          {sectionsVisibility.bio && <BioSection />}
          {sectionsVisibility.stat && <StatSection />}
        </BoxLayout>
      )}
      
      {/* Render BoxLayout for Media and Photos only if one of them is true */}
      {(sectionsVisibility.media || sectionsVisibility.photos) && (
        <BoxLayout bgColor="bg-user-blue">
          {sectionsVisibility.media && <MediaSection />}
          {sectionsVisibility.photos && <PhotoSection />}
        </BoxLayout>
      )}

      {/* Render BoxLayout for Gigs only if it is true */}
      {sectionsVisibility.gigs && (
        <BoxLayout bgColor="bg-user-bg">
          <GigSection />
        </BoxLayout>
      )}
      
      {/* Render BoxLayout for Rider only if it is true */}
      {sectionsVisibility.rider && (
        <BoxLayout bgColor="bg-user-blue">
          <RiderSection />
        </BoxLayout>
      )}

      <ContactSection />
      <Footer />
    </>
  );
};

export default EPK;
