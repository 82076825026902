import { useEffect, useState } from 'react';
import { getFirestore, collection, getDocs } from 'firebase/firestore';
import { db } from '../firebase/firebaseConfig';

export default function PhotoSection() {
  const [photos, setPhotos] = useState([]);  // State to store the fetched photos

  useEffect(() => {
    const fetchPhotos = async () => {
      const db = getFirestore();  // Initialize Firestore
      const photosCollection = collection(db, 'photos');  // Access the 'photos' collection
      const photoSnapshot = await getDocs(photosCollection);  // Fetch the documents

      const photosArray = photoSnapshot.docs.map(doc => doc.data());  // Map through the documents and extract data
      setPhotos(photosArray);  // Set the state with the fetched photos
    };

    fetchPhotos();  // Call the fetch function
  }, []);  // Only run once, on component mount

  return (
    <div className="bg-user-blue pb-12 sm:pb-10">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <ul role="list" className="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8">
          {photos.map((photo) => (
            <li key={photo.fileName} className="relative">
              <div className="group aspect-h-7 aspect-w-10 block w-full overflow-hidden rounded-lg bg-gray-100 focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 focus-within:ring-offset-gray-100">
                <img alt={photo.fileName} src={photo.fileUrl} className="pointer-events-none object-cover group-hover:opacity-75" />
                <button type="button" className="absolute inset-0 focus:outline-none">
                  <span className="sr-only">View details for {photo.fileName}</span>
                </button>
              </div>
              <p className="pointer-events-none mt-2 block truncate text-sm font-medium text-user-bg">{photo.fileName}</p>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}
