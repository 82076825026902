import { useEffect, useState } from 'react';
import { collection, getDocs } from 'firebase/firestore'; // Firestore imports
import { db } from '../firebase/firebaseConfig'; // Import the Firebase config
  
  export default function MediaSection() {

    const [mediaItems, setMediaItems] = useState([]); // State to store media items

    // Fetch media items from Firestore
    useEffect(() => {
      const fetchMediaItems = async () => {
        try {
          // Reference to the 'media' collection in Firestore
          const mediaCollection = collection(db, 'media');
          const mediaSnapshot = await getDocs(mediaCollection); // Fetch all documents in the collection
  
          // Map through the documents and extract data
          const mediaArray = mediaSnapshot.docs.map((doc) => doc.data());

          
  
          // Set the fetched media data into the state
          setMediaItems(mediaArray);
        } catch (error) {
          console.error("Error fetching media items:", error);
        }
      };
  
      fetchMediaItems(); // Call the async function to fetch media
    }, []); // Run the effect only on the first render

    return (
      <section id="media">
      <div className="bg-user-blue pb-24">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-2xl lg:mx-0">
            <h2 className="text-3xl font-bold tracking-tight text-user-bg sm:text-4xl">Mixes and Media...</h2>
            <p className="mt-6 text-lg leading-8 text-user-bg">
              Here's a little taste of my latest mixes and music
            </p>
          </div>
          <ul
            role="list"
            className="mx-auto mt-20 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-3"
          >
            {mediaItems.map((item) => (
              <li key={item.name}>
                {/* Embed YouTube iframe */}
                <div className="aspect-w-16 aspect-h-9">
                  <iframe
                    src={item.url}
                    title={item.title}
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    className="w-full h-full rounded-2xl"
                  />
                </div>
                <h3 className="mt-6 text-lg font-semibold leading-8 tracking-tight text-user-bg">{item.title}</h3>
                <p className="text-base leading-7 text-user-bg">{item.description}</p>
              </li>
            ))}
          </ul>
        </div>
      </div>
      </section>
    );
  }
  