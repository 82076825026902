import { useEffect, useState } from 'react';
import { collection, getDocs } from 'firebase/firestore'; // Import Firestore functions
import { db } from '../firebase/firebaseConfig'; // Import your Firebase config
import { ChevronRightIcon } from '@heroicons/react/20/solid';

export default function RiderSection() {
  const [equipmentList, setEquipmentList] = useState([]); // State to store the fetched equipment list

  // Fetch equipment data from Firestore on component mount
  useEffect(() => {
    const fetchRiderData = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, 'rider')); // Fetch all documents from 'rider' collection
        const equipmentArray = querySnapshot.docs.map((doc) => doc.data()); // Map through the docs and get the data
        
        // Sorting the equipmentArray by category: Audio -> Lights -> FX
        const sortedEquipmentList = equipmentArray.sort((a, b) => {
          const order = { Audio: 1, Lights: 2, FX: 3 };
          return order[a.category] - order[b.category];
        });

        setEquipmentList(sortedEquipmentList); // Update the state with sorted equipment
      } catch (error) {
        console.error('Error fetching rider data:', error);
      }
    };

    fetchRiderData();
  }, []); // Empty dependency array to run only once

  return (
    <section id="rider">
    <div className="bg-user-blue pb-12 sm:pb-10">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:mx-0">
          <h2 className="text-3xl font-bold tracking-tight text-user-bg sm:text-4xl">Equipment</h2>
        </div>
        <ul role="list" className="divide-y divide-gray-100 mt-10">
          {equipmentList.map((item) => (
            <li key={item.name} className="relative flex justify-between gap-x-6 py-5">
              <div className="flex min-w-0 gap-x-4">
                <div className="min-w-0 flex-auto">
                  <p className="text-sm font-semibold leading-6 text-user-bg">
                    <a href={item.url}>
                      <span className="absolute inset-x-0 -top-px bottom-0" />
                      {item.name}
                    </a>
                  </p>
                  <p className="mt-1 flex text-xs leading-5 text-user-bg">
                    {item.description}
                  </p>
                </div>
              </div>
              <div className="flex shrink-0 items-center gap-x-4">
                <div className="hidden sm:flex sm:flex-col sm:items-end">
                  <p className="text-sm leading-6 text-user-bg">{item.category}</p>
                </div>
                <ChevronRightIcon aria-hidden="true" className="h-5 w-5 flex-none text-user-dark-orange" />
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
    </section>
  );
}
