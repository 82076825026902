// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAa_9tzO0b5nInL3YfZHtyR1gG2xHw1x-Q",
  authDomain: "beto-epk-test.firebaseapp.com",
  projectId: "beto-epk-test",
  storageBucket: "beto-epk-test.appspot.com",
  messagingSenderId: "244843071003",
  appId: "1:244843071003:web:8449cbaae6d572e43fdabe",
  measurementId: "G-77BXRZZ11J"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export { db };