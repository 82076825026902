import { useEffect, useState } from 'react';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../firebase/firebaseConfig';

export default function HeroSection() {
  const [newRelease, setNewRelease] = useState(''); 
  const [newReleaseUrl, setNewReleaseUrl] = useState(''); 
  const [heroImageUrl, setHeroImageUrl] = useState(''); 
  const [loading, setLoading] = useState(true); // Loading state

  useEffect(() => {
    const fetchBio = async () => {
      try {
        const docRef = doc(db, 'bio', 'FTyLZ57XHRuAjCjdr37u'); 
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const data = docSnap.data();
          setNewRelease(data.newRelease); 
          setNewReleaseUrl(data.newReleaseUrl); 
          setHeroImageUrl(data.heroImageUrl); 
        } else {
          console.log("No such document!");
        }
      } catch (error) {
        console.error("Error fetching bio data:", error);
      } finally {
        setLoading(false); // Stop loading once data is fetched
      }
    };

    fetchBio();
  }, []); 

  useEffect(() => {
    // Toggle overflow-hidden class to lock/unlock scrolling
    if (loading) {
      document.body.classList.add('overflow-hidden');
    } else {
      document.body.classList.remove('overflow-hidden');
    }

    // Cleanup to ensure scroll is enabled when component unmounts
    return () => {
      document.body.classList.remove('overflow-hidden');
    };
  }, [loading]);

  // Render a loading screen while fetching data
  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen bg-gray-200">
        <div className="text-lg font-semibold text-gray-500">Loading...</div>
      </div>
    );
  }

  return (
    <div className="relative w-screen h-screen bg-white flex items-center justify-center"
    style={{ backgroundImage: "url('/images/hero-bg-2.jpg')" }}>
      <div className="mx-auto max-w-7xl lg:grid lg:grid-cols-12 lg:gap-x-8 lg:px-8">
        <div className="px-6 pb-24 pt-10 sm:pb-32 lg:col-span-7 lg:px-0 lg:pb-56 lg:pt-48 xl:col-span-6">
          <div className="mx-auto max-w-2xl lg:mx-0">
            {/* New Release Section */}
            <div className="hidden sm:mt-32 sm:flex lg:mt-16 mb-8">
              <div className="relative rounded-full bg-user-bg px-6 py-2 text-lg leading-8 text-user-dark-blue ring-1 ring-gray-900/10 hover:ring-gray-900/20">
                New Release! {newRelease}{' '}
                <a href={newReleaseUrl} className="whitespace-nowrap font-semibold text-user-dark-orange">
                  <span aria-hidden="true" className="absolute inset-0" />
                  Listen Now! <span aria-hidden="true">&rarr;</span>
                </a>
              </div>
            </div>
            <h1 className="mt-16 text-5xl font-bold tracking-tight text-gray-900 sm:mt-10 sm:text-7xl">
              Beto Moresca
            </h1>
            <p className="mt-8 text-xl leading-9 text-gray-600">
            Creating the perfect soundtrack for every moment, where every beat brings the rhythm to life
            </p>
            <div className="mt-12 flex items-center gap-x-6">
              <a
                href="#bio"
                className="rounded-md bg-user-dark-orange px-5 py-3 text-lg font-semibold text-white shadow-sm hover:bg-user-light-orange hover:text-user-dark-orange"
              >
                Enter
              </a>
            </div>
          </div>
        </div>
        <div className="relative hidden lg:flex lg:items-center lg:justify-center lg:col-span-5 lg:-mr-8 xl:absolute xl:inset-0 xl:left-1/2 xl:mr-0">
          <img
            alt="Hero section"
            src={heroImageUrl || 'https://images.unsplash.com/photo-1498758536662-35b82cd15e29?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2102&q=80'}
            className="h-auto object-contain mx-auto"
          />
        </div>
      </div>
    </div>
  );
}
