import { useEffect, useState } from 'react';
import { doc, getDoc } from 'firebase/firestore'; // Firestore imports
import { db } from '../firebase/firebaseConfig'; // Import Firebase config

export default function BioSection() {
  const [bioText, setBioText] = useState(''); // State for bio text
  const [bioImageUrl, setBioImageUrl] = useState(''); // State for bio image URL

  useEffect(() => {
    const fetchBioData = async () => {
      try {
        // Get the specific document from the 'bio' collection
        const docRef = doc(db, 'bio', 'FTyLZ57XHRuAjCjdr37u'); // Replace with the actual document ID
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const data = docSnap.data();
          setBioText(data.bioText); // Set the bio text
          setBioImageUrl(data.bioImageUrl); // Set the bio image URL
        } else {
          console.log('No such document in the bio collection!');
        }
      } catch (error) {
        console.error('Error fetching bio data:', error);
      }
    };

    fetchBioData(); // Fetch bio data on component mount
  }, []); // Empty dependency array means this runs once when the component mounts

  return (
    <section id="bio">
    <div className="overflow-hidden bg-user-bg py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
          <div className="lg:ml-auto lg:pl-4 lg:pt-4">
            <div className="lg:max-w-lg">
              <h2 className="text-base font-semibold leading-7 text-user-dark-orange">Dive into the rhythm!</h2>
              <p className="mt-2 text-3xl font-bold tracking-tight text-user-dark-blue sm:text-4xl">Dance is a ritual</p>
              <p className="mt-6 text-lg leading-8 text-user-dark-blue">
                {bioText ? bioText : 'Loading bio...'} 
              </p>
            </div>
          </div>
          <div className="hidden lg:flex lg:items-start lg:justify-end lg:order-first">
            {bioImageUrl ? (
              <img
                alt="Bio"
                src={bioImageUrl} 
                className="w-[48rem] max-w-none rounded-xl shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem]"
              />
            ) : (
              <p>Loading image...</p> 
            )}
          </div>
        </div>
      </div>
    </div>
    </section>
  );
}
