import { useEffect, useState } from 'react';
import { doc, getDoc } from 'firebase/firestore'; // Firestore imports
import { db } from '../firebase/firebaseConfig'; // Firebase config

export default function StatSection() {
  const [stat1, setStat1] = useState({ stat: '', statDescription: '' });
  const [stat2, setStat2] = useState({ stat: '', statDescription: '' });
  const [stat3, setStat3] = useState({ stat: '', statDescription: '' });

  useEffect(() => {
    const fetchStats = async () => {
      try {
        const docRef = doc(db, 'stats', '66JElIgfW6RXqjdoxXAm'); // Replace with your actual document ID
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const data = docSnap.data();
          setStat1({ stat: data.stat1, statDescription: data.stat1Description });
          setStat2({ stat: data.stat2, statDescription: data.stat2Description });
          setStat3({ stat: data.stat3, statDescription: data.stat3Description });
        } else {
          console.log("No such document in stats collection!");
        }
      } catch (error) {
        console.error("Error fetching stats:", error);
      }
    };

    fetchStats();
  }, []);

  return (
    <div className="bg-user-bg">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-7xl flex justify-center items-center lg:mx-0"></div>
        <div className="mx-auto mt-16 flex max-w-2xl flex-col gap-8 lg:mx-0 lg:mt-20 lg:max-w-none lg:flex-row lg:items-end">
          <div className="flex flex-col-reverse justify-between gap-x-16 gap-y-8 rounded-2xl bg-user-coral p-8 sm:w-3/4 sm:max-w-md sm:flex-row-reverse sm:items-end lg:w-72 lg:max-w-none lg:flex-none lg:flex-col lg:items-start">
            <p className="flex-none text-3xl font-bold tracking-tight text-user-bg">{stat1.stat}</p>
            <div className="sm:w-80 sm:shrink lg:w-auto lg:flex-none">
              <p className="text-lg font-semibold tracking-tight text-user-bg">{stat1.statDescription}</p>
            </div>
          </div>
          <div className="flex flex-col-reverse justify-between gap-x-16 gap-y-8 rounded-2xl bg-user-blue p-8 sm:flex-row-reverse sm:items-end lg:w-full lg:max-w-sm lg:flex-auto lg:flex-col lg:items-start lg:gap-y-44">
            <p className="flex-none text-3xl font-bold tracking-tight text-user-bg">{stat2.stat}</p>
            <div className="sm:w-80 sm:shrink lg:w-auto lg:flex-none">
              <p className="text-lg font-semibold tracking-tight text-user-bg">{stat2.statDescription}</p>
            </div>
          </div>
          <div className="flex flex-col-reverse justify-between gap-x-16 gap-y-8 rounded-2xl bg-user-dark-orange p-8 sm:w-11/12 sm:max-w-xl sm:flex-row-reverse sm:items-end lg:w-full lg:max-w-none lg:flex-auto lg:flex-col lg:items-start lg:gap-y-28">
            <p className="flex-none text-3xl font-bold tracking-tight text-user-bg">{stat3.stat}</p>
            <div className="sm:w-80 sm:shrink lg:w-auto lg:flex-none">
              <p className="text-lg font-semibold tracking-tight text-user-bg">{stat3.statDescription}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
