import { useEffect, useState } from 'react';
import { collection, getDocs } from 'firebase/firestore'; // Firestore imports
import { db } from '../firebase/firebaseConfig'; // Your Firebase configuration
import Flag from 'react-world-flags'; // Assuming you're using react-world-flags for flag icons

// Helper function to combine class names
function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

// Helper function to convert DD/MM/YYYY to a valid date object
function parseDate(dateString) {
  const [day, month, year] = dateString.split('/');
  return new Date(`${year}-${month}-${day}`); // Format as YYYY-MM-DD for JavaScript Date
}

export default function GigSection() {
  const [gigs, setGigs] = useState([]); // State to store the gigs

  useEffect(() => {
    const fetchGigs = async () => {
      try {
        const gigsCollection = collection(db, 'gigs'); // Reference to the 'gigs' collection in Firestore
        const gigsSnapshot = await getDocs(gigsCollection); // Fetch the documents in the collection

        const gigsArray = gigsSnapshot.docs.map((doc) => ({
          id: doc.id, // Assign the document ID
          ...doc.data(), // Spread the document fields (country, city, venue, date)
        }));

        // Sort gigs by date (older to newer)
        gigsArray.sort((a, b) => parseDate(a.date) - parseDate(b.date));

        setGigs(gigsArray); // Store the gigs data in the state
      } catch (error) {
        console.error("Error fetching gigs:", error);
      }
    };

    fetchGigs(); // Fetch gigs data on component mount
  }, []);

  return (
    <section id="gigs">
    <div className="bg-user-bg pb-12 sm:pb-10">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:mx-0">
          <h2 className="text-3xl font-bold tracking-tight text-user-dark-blue sm:text-4xl">Past Gigs...</h2>
        </div>
        <div className="flow-root mt-10">
          <ul role="list" className="-mb-8">
            {gigs.map((gig, gigIdx) => (
              <li key={gig.id}>
                <div className="relative pb-8">

                  <div className="relative flex space-x-3 items-center"> {/* Add items-center to align vertically */}
                    {/* Adjust flag container */}
                    <div className="flex items-center justify-center h-16 w-16 rounded-full overflow-hidden">
                      <Flag code={gig.country} className="h-full w-full object-cover" />
                    </div>
                    <div className="flex min-w-0 flex-1 justify-between space-x-4 pt-1.5">
                      <div>
                        {/* Increase the size of the city and venue text and format it */}
                        <p className="text-lg text-user-dark-blue">
                          <span className="font-bold">{gig.city}</span> {/* Bold city */}
                          <span className="text-sm"> @ {gig.venue}</span> {/* Smaller venue */}
                        </p>
                      </div>
                      <div className="whitespace-nowrap text-right text-sm text-user-dark-blue">
                        <time dateTime={gig.date}>{gig.date}</time>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
    </section>
  );
}
